import type { NextRouter } from 'next/router';
import { useRouter } from 'next/compat/router';
import { useContext, useEffect } from 'react';
import { default as GTMContext } from './gtm/context';

type GetInitialOpts = {
  router: NextRouter;
};

type GTMOpts = {
  /**
   * Dispatched as a single request. Use initial to set events statically. Use
   * getInitial to lazily evaluate events until right before sending them.
   */
  initial?: Record<any, any>[];
  getInitial?: (opts?: GetInitialOpts) => Record<any, any>[];
};

type UseTrackingOpts = {
  legacy?: {
    pageName?: string;
    pageType?: string;
    categoryName?: string;
  };
  gtm: GTMOpts;
  fb?: () => void;
};

/**
 * This hook enables tracking of page views and impressions as the user navigates between pages within the SPA.
 * It does this by tracking on route change events instead of on page load.
 */
export function useTracking({
  legacy: { pageName, pageType, categoryName } = {},
  gtm,
  fb,
}: UseTrackingOpts) {
  const router = useRouter();

  // don't destructure
  const context = useContext(GTMContext);

  const track = () => {
    global.pageName = pageName;
    global.pageType = pageType;
    global.categoryName = categoryName;

    // gtm
    const initial = gtm.initial || gtm.getInitial({ router });
    context.track(initial);

    // fb
    fb?.();
  };

  useEffect(() => {
    track();
  }, [router?.asPath]);
}
