export const GTM_PRODUCT_CLICK_EVENT = 'productClicked';

export const getEnv = () =>
  ['preview', 'development'].includes(process.env.NEXT_PUBLIC_VERCEL_ENV)
    ? 'Development'
    : 'Consumer';

export const GLOBAL_SETTINGS = {
  event: 'global_settings',
  br_view_id: 'ved',
  environment: getEnv(),
};

// mostly matched from existing legacy tracking
export enum PAGE_TYPES {
  CategoryListing = 'Category Listing Page',
  ProductListing = 'Product Listing Page',
  Search = 'Search',
  SearchNoResults = 'Search No Results',
  Product = 'Product',
  ProductChoice = 'Product Choice',
  CollectionProduct = 'Collection Product',
  Home = 'Home',
  CustomSite = 'Custom Site',
  GiftFinder = 'Gift Finder',
  GiftFinderResults = 'Gift Finder Results',
  BlogAuthor = 'Blog Author',
  BlogHome = 'Blog Home',
  BlogCategory = 'Blog Category',
  BlogListing = 'Blog Listing',
  BlogPost = 'Blog Post',
  ThingsToDoHome = 'ThingsToDo Home',
  ThingsToDoListing = 'ThingsToDo Listing',
  ThingsToDoPage = 'ThingsToDo Page',
  ThingsToDoCounties = 'ThingsToDo Counties',
  ThingsToDoSearch = 'ThingsToDo Search',
}
